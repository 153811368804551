import { Translations } from './types'

const ja: Translations = {
  taxIn: '税込',
  common: {
    exhibitionArea: '展示エリア',
  },
  onboarding: {
    title: 'ショッピングガイド',
    step1: '商品近くの\nQRコードをスキャン',
    step2: 'レジでお支払い\n※現金利用不可',
    step3: 'その場で受け取り',
    start: 'お買い物を始める',
    recommendations:
      'このサイトは、SafariやChromeなどの\nWebブラウザでの閲覧を推奨しています。',
  },
  checkoutGuide: {
    headline: '商品が決まりましたらレジカウンターへ',
    text: 'お気に召した商品は見つかりましたか？\n欲しいものが決まりましたらレジカウンターにてお支払い手続きにお進みください。',
  },
  cart: {
    includeNotInCatalog: 'カタログ非掲載となった商品が含まれています',
    deleteItemNotInCatalog: 'お手数ですがカートから削除してください。',
    failedToChangeAmount: '在庫数を超えたため、購入数量の変更に失敗しました',
    failedToChangeReceivedPlace: '受け取り場所変更に失敗しました',
    failedToChangeWrappingType: 'ラッピング設定の変更に失敗しました',
    agreeToTermsOfUseAndPrivacyPolicy:
      '{termsOfUse}と{privacyPolicy}に同意します',
    termsOfUse: '利用規約',
    privacyPolicy: 'プライバシーポリシー',
    backTo: 'エリアガイドに戻る',
    shoppingBag: 'お買い物バッグ',
    guideText: '商品の受取方法を選択し、\nお支払いコードを発行してください。',
    guideCaution: 'お求め商品の在庫手配はまだ完了していません。',
    wrappingMessage: 'ギフトラッピングを希望する',
    wrappingPrice: '包装料',
    wrappingCaution: 'レジにて詳細をお伺いします。',
    subTotal: '小計',
    gift: 'ギフト',
    total: '合計金額',
    paymentButton: '店頭でお支払い',
    paymentNotice:
      '当店はキャッシュレス決済となります。\n「店頭でお支払い」からお支払いコードを発行し、\nレジカウンターにてご提示ください。',
    invalidCouponTitle:
      '無効なクーポンが選択されているため、使用できませんでした。',
    invalidCouponText: 'クーポンの使用を取り消してください。',
    noProductsTitle: 'お支払いコードが発行できませんでした。',
    noProductsText: '在庫を用意できる商品がありません。',
    qrCodeTitle: 'お支払いコードを発行しました。',
    qrCodeTextLine:
      '認証されたLINEアカウントにメッセージをお送りしました。届いていない場合、当アカウントをブロックしていないかご確認ください。',
    qrCodeTextEmail:
      '{email} にお支払いのためのQRコードをお送りしています。メールが届いていない場合は、再度通知設定をお願いいたします。',
    remove: '削除',
    cancel: 'キャンセル',
    confirmToRemoveProduct:
      '商品をバッグから削除します。\n本当によろしいですか？',
    nothingInBag: 'まだバッグには何も入っていません。',
    nothingInBagText:
      '今いるエリアからあなたにぴったりの商品を\n探しにいきましょう！',
    noStock: '在庫をご用意できませんでした',
    fewStock: 'ご希望数をご用意できませんでした',
    priceChanged: '価格が変更された商品がございます',
    confirmMessage: 'メッセージを確認する',
    cartChanged: '申し訳ございません。\nショッピングバッグに変更がございます。',
    close: '閉じる',
  },
  productDetail: {
    lowStock: '残りわずか',
    more: 'もっと詳細を見る',
    less: '詳細を閉じる',
    addToCart: 'お買い物バッグに入れる',
    soldOut: 'SOLD OUT',
    addedToCart: 'バッグに商品を追加しました',
    failedToAddToCart: '商品をお取り置きできませんでした',
  },
  aiCatalog: {
    scanQRCode: '商品の近くにあるQRコードを読み取ってください',
  },
  brands: {
    inStock: '在庫あり',
  },
}

export default ja
