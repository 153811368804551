import { Translations } from './types'

const ko: Translations = {
  chat: {
    brandNotFound: '브랜드 정보를 가져오는 데 실패했습니다.',
    alreadyReceived: '이미 제품을 수령하셨습니다.',
    receivedQrError: '수령한 QR 코드를 가져오는 데 실패했습니다.',
    messages: {
      error: {
        text: '아직 결제가 완료되지 않았습니다。',
      },
      brandName: {
        text: '이 브랜드의 상품 목록을 확인해 주세요.',
      },
      brand: {
        text: '이 브랜드의 상품 목록을 확인하세요',
        buttonTitle: '목록 보기',
      },
      completePayment: {
        text: '계산대에서 결제를 완료한 후 아래 버튼을 클릭하십시오. 제품 수령 시간에 대한 안내를 받으실 수 있습니다.',
        buttonTitle: '결제 완료',
      },
      downloadReceipt: {
        title: '영수증을 사용하시나요?',
        text: '장치에 영수증 데이터(PDF)를 다운로드할 수 있습니다. 영수증을 발급하지 않으시면 반품이나 교환을 할 수 없음을 유의하십시오.',
        note: '이 화면을 닫으면 영수증을 재발행할 수 없습니다.',
        buttonTitle: '영수증 다운로드(PDF)',
      },
      fixed: {
        hello: {
          text: '안녕하세요. 저는 ChooseBuddy입니다.\n이곳은 CHOOSEBASE SHIBUYA 채팅 지원입니다.\n이 상점의 모든 결제는 현금 없이 이루어지므로 현금을 사용하지 않고도 쇼핑을 즐길 수 있습니다.\n\n브랜드의 제품을 보려면 아래의 "Scan" 메뉴에서 해당 브랜드의 QR 코드를 스캔하세요.',
        },
        scan: {
          text: '다른 브랜드의 제품을 찾으려면 아래의 "스캔" 메뉴에서 해당 브랜드의 QR 코드를 스캔하십시오.',
        },
        readyToBuy: {
          title: '계산대로 가자!',
          text: '구매할 준비가 되셨나요?\n이 화면을 계산원에게 제시해 주세요.',
        },
      },
      paymentQr: {
        expirationDate: 'QR코드 만료일',
        text: 'QR코드를 계산대에 제시해 주세요',
      },
      receivedQr: {
        title: '구매해 주셔서 감사합니다!',
        text: '결제가 완료되었습니다.\n주문하신 상품을 준비하는데 잠시만 기다려주세요.',
        receivingTime: '상품 수령 시간',
        receivingText:
          '상기 시간에 다시 계산대로 오셔서 주문하신 상품을 드리겠습니다.',
      },
    },
  },
  taxIn: 'Tax In',
  common: {
    exhibitionArea: '전시 구역',
  },
  onboarding: {
    title: '쇼핑 가이드',
    step1: '스마트폰으로\n상품 선택',
    step2: '매장 레지스터에서\n결제',
    step3: '장소에서 받기',
    start: '쇼핑 시작',
    backToShopping: '쇼핑으로 돌아가기',
    recommendations:
      '이 사이트는 Safari 또는 Chrome과 같은\n웹 브라우저로 확인하는 것이 좋습니다.',
  },
  checkoutGuide: {
    headline: '상품을 결정하셨다면 레지 카운터로',
    text: '좋아하는 상품을 찾으셨나요?\n원하는 것을 결정하셨다면 레지 카운터로 이동하여 결제 절차를 진행해 주세요.',
  },
  cart: {
    includeNotInCatalog: '카탈로그에 등록되지 않은 상품이 포함되어 있습니다.',
    deleteItemNotInCatalog: '장바구니에서 삭제해 주시기 바랍니다.',
    failedToGenerateQr: '결제 코드를 발급하지 못했습니다.',
    failedToChangeAmount:
      '수량이 사용 가능한 재고를 초과했습니다. 카트를 업데이트하지 못했습니다.',
    failedToChangeReceivedPlace: '배송지 변경에 실패했습니다.',
    failedToChangeWrappingType: '포장 종류 변경에 실패했습니다.',
    agreeToTermsOfUseAndPrivacyPolicy:
      '{termsOfUse} 및 {privacyPolicy}에 동의해 주세요',
    termsOfUse: '이용약관',
    privacyPolicy: '개인정보 처리방침',
    backTo: '채팅으로 돌아가기',
    shoppingBag: '장바구니',
    guideText: '상품 수령 방법을 선택하고,\n결제 QR 코드를 발급해주세요.',
    guideCaution: '구매하신 상품의 재고 조정이 아직 완료되지 않았습니다.',
    wrappingMessage: '선물 포장을 원합니다',
    wrappingPrice: '포장료',
    wrappingCaution: '레지에서 상세 내용을 확인합니다.',
    subTotal: '소계',
    gift: '선물',
    total: '총액',
    paymentButton: '매장에서 결제',
    paymentNotice:
      '저희 매장은 무현금 결제입니다.\n"매장에서 결제"에서 결제 QR 코드를 발급하고,\n레지 카운터에서 제시해 주세요.',
    invalidCouponTitle: '선택한 쿠폰이 유효하지 않아 사용할 수 없습니다.',
    invalidCouponText: '쿠폰 사용을 취소해 주세요.',
    noProductsTitle: '결제 코드를 발급할 수 없습니다.',
    noProductsText: '재고를 준비할 수 있는 상품이 없습니다.',
    qrCodeTitle: '결제 코드를 발급했습니다.',
    qrCodeTextLine:
      '인증된 LINE 계정으로 메시지를 보냈습니다. 받지 못한 경우, 이 계정이 차단되지 않았는지 확인해 주세요.',
    qrCodeTextEmail:
      '{email}로 결제용 QR 코드를 보내드렸습니다. 이메일이 도착하지 않은 경우, 다시 한 번 알림 설정을 부탁드립니다.',
    remove: '삭제',
    cancel: '취소',
    confirmToRemoveProduct:
      '상품을 장바구니에서 삭제합니다.\n정말로 삭제하시겠습니까?',
    nothingInBag: '장바구니에 담긴 상품이 없습니다.',
    nothingInBagText: '현재 위치에서 당신에게 맞는 상품을\n찾아보러 가볼까요?',
    noStock: '재고를 준비할 수 없습니다',
    fewStock: '희망 수량을 준비할 수 없습니다',
    priceChanged: '가격이 변경된 상품이 있습니다',
    confirmMessage: '메시지 확인하기',
    cartChanged: '죄송합니다.\n장바구니에 변경 사항이 있습니다.',
    close: '닫기',
  },
  productDetail: {
    lowStock: '잔여 수량',
    more: '자세히 보기',
    less: '자세한 정보 닫기',
    addToCart: '장바구니에 담기',
    soldOut: '품절',
    addedToCart: '상품을 장바구니에 추가했습니다',
    failedToAddToCart: '상품을 예약할 수 없습니다',
  },
  aiCatalog: {
    scanQRCode: '항목 근처의 QR코드를 스캔합니다.',
  },
  brands: {
    inStock: 'InStock',
  },
}

export default ko
