import { Translations } from './types'

const zh: Translations = {
  chat: {
    brandNotFound: '获取品牌信息失败',
    alreadyReceived: '您已经收到了产品',
    receivedQrError: '获取收到的QR码失败',
    messages: {
      error: {
        text: '尚未完成付款。',
      },
      brandName: {
        text: '请查看此品牌的商品列表',
      },
      brand: {
        text: '查看此品牌的商品列表',
        buttonTitle: '点击这里查看',
      },
      completePayment: {
        text: '在结帐柜台完成付款后，请单击下面的按钮。您将收到产品取货时间的通知。',
        buttonTitle: '结算完成',
      },
      downloadReceipt: {
        title: '您使用收据吗？',
        text: '您可以将收据数据（PDF）下载到您的设备。请注意，如果您不希望开具收据，则无法退货或换货。',
        note: '请注意，一旦关闭此屏幕，您将无法重新开具收据。',
        buttonTitle: '下载收据（PDF）',
      },
      fixed: {
        hello: {
          text: '你好。我是 ChooseBuddy。\nCHOOSEBASE SHIBUYA聊天支持。\n这家商店的所有付款都是无现金的，所以您可以在不使用现金的情况下享受购物。\n\n要查看某个品牌的产品，请从下面的 “Scan” 菜单中扫描该品牌的二维码。',
        },
        scan: {
          text: '要浏览其他品牌的产品，请从下面的“扫描”菜单中扫描该品牌的QR码。',
        },
        readyToBuy: {
          title: '我们去收银台吧!',
          text: '准备好购买了吗？\n请向收银员出示此屏幕。',
        },
      },
      paymentQr: {
        expirationDate: '二维码有效期至',
        text: '请向收银员出示二维码。',
      },
      receivedQr: {
        title: '感谢您的购买！',
        text: '支付已完成。\n请稍等片刻，我们将为您准备订单。',
        receivingTime: '商品领取时间',
        receivingText:
          '请在上述时间再次来到结账柜台。\n我们将为您提供您订购的商品。',
      },
    },
  },
  taxIn: 'Tax In',
  common: {
    exhibitionArea: '展示区',
  },
  onboarding: {
    title: '购物指南',
    step1: '使用智能手机\n选择商品',
    step2: '在店内结账台\n进行无现金支付',
    step3: '立即领取',
    start: '开始购物',
    backToShopping: '返回购物',
    recommendations: '建议使用Safari或Chrome等\nWeb浏览器查看本网站。',
  },
  checkoutGuide: {
    headline: '一旦您决定购买商品，\n请前往收银台',
    text: '您找到了喜欢的商品吗？\n一旦您决定购买什么，\n请前往收银台进行付款手续。',
  },
  cart: {
    includeNotInCatalog: '目录中包含不再列入目录的产品。',
    deleteItemNotInCatalog: '请从购物车中删除这些商品。',
    failedToGenerateQr: 'QR码生成失败。请在通信条件良好的地方再试一次。',
    failedToChangeAmount: '数量超过现有库存。更新购物车失败。',
    failedToChangeReceivedPlace: '无法更改取货地点',
    failedToChangeWrappingType: '无法更改包装类型',
    agreeToTermsOfUseAndPrivacyPolicy: '我同意{termsOfUse}和{privacyPolicy}.',
    termsOfUse: '使用规章',
    privacyPolicy: '隐私政策',
    backTo: '返回聊天',
    shoppingBag: '购物袋',
    guideText: '选择您希望收到商品的方式，\n将发出付款QR码。',
    guideCaution: '库存安排尚未完成。',
    wrappingMessage: '我想要礼品包装',
    wrappingPrice: '包装费',
    wrappingCaution: '我们将在收银台询问详细信息。',
    subTotal: '小计',
    gift: '礼物',
    total: '总计',
    paymentButton: '继续付款程序',
    paymentNotice:
      '我们的商店接受无现金支付。\n只需在收银台出示QR码，\n您可以通过在收银台出示QR码进行快速支付。',
    invalidCouponTitle: '选择的优惠券无效，无法使用。',
    invalidCouponText: '请取消使用优惠券。',
    noProductsTitle: '无法发出付款码。',
    noProductsText: '没有可用于库存的产品。',
    qrCodeTitle: '发出付款码。',
    qrCodeTextLine:
      '我们已向您的认证LINE帐户发送了消息。如果您没有收到，请检查是否已阻止此帐户。',
    qrCodeTextEmail:
      '我们正在向{email}发送付款的QR码。如果您没有收到电子邮件，请再次设置通知设置。',
    remove: '删除',
    cancel: '取消',
    confirmToRemoveProduct: '从购物袋中删除产品。\n确定吗？',
    nothingInBag: '购物袋中没有任何内容。',
    nothingInBagText:
      '您所在区域的合适产品。\n让我们从您所在区域找到适合您的产品！',
    noStock: '库存不足',
    fewStock: '库存不足',
    priceChanged: '某些商品的价格已更改',
    confirmMessage: '检查消息',
    cartChanged: '很抱歉。\n购物袋中有变化。',
    close: '关闭',
  },
  productDetail: {
    lowStock: '库存不足',
    more: '更多',
    less: '关闭详细信息',
    addToCart: '放入购物袋',
    soldOut: '售罄',
    addedToCart: '已将商品添加到购物袋',
    failedToAddToCart: '无法预订商品',
  },
  aiCatalog: {
    scanQRCode: '扫描产品附近的 QR 码。',
  },
  brands: {
    inStock: 'InStock',
  },
}

export default zh
